<div class="form-container">
  <h2>Your profile settings:</h2>
  <h3 *ngIf="!isExtrenalUser()">This feature is only available for external users.</h3>
  <form *ngIf="isExtrenalUser()" [formGroup]="profileForm" (ngSubmit)="saveProfile()">
    <mat-radio-group class="radio-group" formControlName="GenderCode">
      <mat-label>Gender</mat-label>
      @for (gender of genders; track gender) {
        <mat-radio-button class="radio-button" color="primary" [value]="gender.value">{{gender.viewValue}}</mat-radio-button>
      }
    </mat-radio-group>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">Given Name</mat-label>
      <input title="given name" matInput formControlName="FirstName" maxlength="50">
      <mat-hint class="radio-field">Your given name (also known as first name).</mat-hint>
      <mat-error *ngIf="profileForm.get('FirstName')?.invalid">Invalid given name.</mat-error>
    </mat-form-field>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">Surname</mat-label>
      <input title="surname" matInput formControlName="LastName" maxlength="50">
      <mat-hint class="radio-field">Your surname (also known as family name or last name).</mat-hint>
      <mat-error *ngIf="profileForm.get('LastName')?.invalid">Invalid surname.</mat-error>
    </mat-form-field>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">Title</mat-label>
      <input title="title" matInput formControlName="Title" maxlength="50">
      <mat-hint class="radio-field">Your title.</mat-hint>
      <mat-error *ngIf="profileForm.get('Title')?.invalid">Invalid title.</mat-error>
    </mat-form-field>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">Company</mat-label>
      <input title="company" matInput formControlName="Company" maxlength="50">
      <mat-hint class="radio-field">Your company.</mat-hint>
      <mat-error *ngIf="profileForm.get('Company')?.invalid">Invalid company.</mat-error>
    </mat-form-field>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">Street Address</mat-label>
      <input title="street address" matInput formControlName="StreetAddress" maxlength="250">
      <mat-hint class="radio-field">Your street address.</mat-hint>
      <mat-error *ngIf="profileForm.get('StreetAddress')?.invalid">Invalid street address.</mat-error>
    </mat-form-field>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">Postal Code</mat-label>
      <input title="postal code" matInput formControlName="PostalCode" maxlength="20">
      <mat-hint class="radio-field">Your postal code.</mat-hint>
      <mat-error *ngIf="profileForm.get('PostalCode')?.invalid">Invalid postal code.</mat-error>
    </mat-form-field>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">City</mat-label>
      <input title="city" matInput formControlName="City" maxlength="80">
      <mat-hint class="radio-field">Your city.</mat-hint>
      <mat-error *ngIf="profileForm.get('City')?.invalid">Invalid city.</mat-error>
    </mat-form-field>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">Country</mat-label>
      <mat-select formControlName="Country">
        @for (country of countries; track country) {
          <mat-option [value]="country.id">{{country.name}}</mat-option>
        }
      </mat-select>
      <mat-hint class="radio-field">Your country.</mat-hint>
    </mat-form-field>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">Preferred Language</mat-label>
      <mat-select formControlName="PreferredLanguage">
        @for (language of languages; track language) {
          <mat-option [value]="language.value">{{language.viewValue}}</mat-option>
        }
      </mat-select>
      <mat-hint>Your preferred language.</mat-hint>
    </mat-form-field>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">Telephone Number</mat-label>
      <input title="Your telephone number." matInput formControlName="TelephoneNumber" maxlength="50">
      <mat-hint class="radio-field">Your telephone number.</mat-hint>
      <mat-error *ngIf="profileForm.get('TelephoneNumber')?.invalid">Invalid telephone number.</mat-error>
    </mat-form-field>
    <mat-form-field class="radio-field" appearance="outline">
      <mat-label class="radio-label">Group</mat-label>
      <mat-select formControlName="Group">
        @for (group of groups; track group) {
          <mat-option [value]="group.value">{{group.viewValue}}</mat-option>
        }
      </mat-select>
      <mat-hint class="radio-field">Please contact us if this changes.</mat-hint>
    </mat-form-field>
    <button mat-raised-button class="form-button" [ngClass]="{'mobile': isXSmall}" type="submit" [disabled]="!(profileForm.valid && profileForm.dirty)">Submit</button>
  </form>
 </div>
